import React from "react";
import ToolsList from "../../compoenents/ToolsList/ToolsList";

function Dashboard() {
  return (
    <div className="flex justify-center ">
      <ToolsList />
    </div>
  );
}

export default Dashboard;
