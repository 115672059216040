import React from "react";

function Timer() {
  return (
    <div className="p-4">
      <div className="text-xl border-b-2"> Pomodoro 🍅 </div>
      <div className="w-full  flex flex-col md:flex-row gap-4 p-2"></div>
    </div>
  );
}

export default Timer;
